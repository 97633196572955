<template>
  <div class="game-form fill-height">
    <Alert :show="showAlert && !!showError?.message">
      <SvgSprite symbol="icons-alert" size="0 0 14 11" />
      <span>Erreur : {{ showError?.message }}</span>
    </Alert>
    <BackBtn />
    <div class="wrapper wrapper--small p-l">
      <h3 class="mb-m">Ajouter une fiche jeu</h3>

      <form
        ref="formel"
        @submit.prevent="onSubmit"
        method="POST"
        enctype="multipart/form-data"
      >
        <div class="mb-s">
          <h5 class="mb-xxs">Titre du jeu *</h5>
          <FormInput name="title" :required="true" />
        </div>

        <div class="infos">
          <div class="mb-s">
            <h5 class="mb-xxs">Nombre de joueurs *</h5>
            <FormInput name="number" :required="true" />
          </div>

          <div class="mb-s">
            <h5 class="mb-xxs">Branche *</h5>
            <FormInput name="age" :required="true" />
          </div>

          <div class="mb-s">
            <h5 class="mb-xxs">Durée du jeu (min) *</h5>
            <FormInput name="duration" :required="true" />
          </div>
        </div>

        <label class="m-s">
          <h5 class="mb-xxs">Résumé et décorum *</h5>
          <textarea
            name="resume"
            placeholder="max 450 caractères espace compris"
            maxlength="450"
            required
          ></textarea>
        </label>

        <div class="m-s">
          <h5 class="mb-xxs">Matériel</h5>

          <label
            class="piece mb-xxs"
            v-for="(item, index) in materials"
            :key="item"
          >
            <div>Matériel {{ index + 1 }}</div>
            <input type="text" :name="`material_${index + 1}`" />
          </label>

          <a class="add" @click.prevent="addMaterial" v-if="materials < 50">
            <SvgSprite symbol="icons-add" size="16" />
            <span>Ajouter un matériel</span>
          </a>
        </div>

        <div class="m-s">
          <h5 class="mb-xxs">Rôle des animateurs</h5>

          <label
            class="piece mb-xxs"
            v-for="(item, index) in roles"
            :key="item"
          >
            <div>Rôle {{ index + 1 }}</div>
            <input type="text" :name="`role_${index + 1}`" />
          </label>

          <a class="add" @click.prevent="addRole" v-if="roles < 20">
            <SvgSprite symbol="icons-add" size="16" />
            <span>Ajouter un rôle</span>
          </a>
        </div>

        <label class="m-s">
          <h5 class="mb-xxs">Préparation du jeu *</h5>
          <textarea name="preparation" required></textarea>
        </label>

        <label class="m-s">
          <h5 class="mb-xxs">Objectif principal et fin du jeu *</h5>
          <textarea name="objective" required></textarea>
        </label>

        <label class="m-s">
          <h5 class="mb-xxs">Déroulement du jeu *</h5>
          <textarea name="progress" required></textarea>
        </label>

        <div class="variants m-s">
          <div class="variant-choice">
            <h5 class="mb-xxs">Des variantes existent pour ce jeu</h5>

            <div class="col mb-s">
              <label class="input-radio">
                <input
                  v-model="variant"
                  name="variant"
                  value="yes"
                  type="radio"
                />
                <span>Oui</span>
              </label>

              <label class="input-radio">
                <input
                  v-model="variant"
                  name="variant"
                  value="no"
                  type="radio"
                />
                <span>Non</span>
              </label>
            </div>
          </div>

          <div class="variant-desc bt pt-s" v-if="variant === 'yes'">
            <h5 class="mb-xxs">Description de la variante</h5>
            <textarea name="variantdesc"></textarea>
          </div>
        </div>

        <div class="photos m-s">
          <h5 class="mb-xxs">Photo</h5>
          <Upload
            ref="uploadPhotos"
            name="photos[]"
            icon="image"
            :max="3"
            :options="{
              allowFileTypeValidation: true,
              acceptedFileTypes: ['image/*'],
              allowFileSizeValidation: true,
              maxFileSize: '5MB',
            }"
          />
        </div>

        <div class="documents m-s">
          <h5 class="mb-xxs">Upload un document</h5>
          <Upload
            ref="uploadDocuments"
            name="documents[]"
            :max="3"
            :options="{
              allowFileTypeValidation: true,
              acceptedFileTypes: ['application/pdf'],
              allowFileSizeValidation: true,
              maxFileSize: '5MB',
            }"
          />
        </div>

        <div class="error m-m" v-if="showError?.message">
          <h4>{{ showError.message }}</h4>

          <ul>
            <li v-for="(val, key) in showError?.errors" :key="key">
              {{ val.join(',') }}
            </li>
          </ul>
        </div>

        <Btn class="send mt-s">Je publie</Btn>
      </form>
    </div>

    <Feedback v-model:visible="feedbackVisible" :badge="badgeFeedback">
      <template v-if="loading">
        <h4 class="mb-xxs">Document en cours d'envoi...</h4>
        <p>Ton activité est en cours d'envoi...</p>
      </template>

      <template v-else>
        <h4 class="mb-xxs">Merci !</h4>
        <p>
          Ton activité a bien été envoyée. Après vérification, elle sera publiée
          et disponible pour tous les Animateurs et Animatrices.
        </p>
      </template>
    </Feedback>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, reactive } from 'vue'
import { create } from '@/guides/endpoints/activity'
import { useRoute } from 'vue-router'

import Alert from '@/components/Alert.vue'
import BackBtn from '@/components/BackBtn.vue'
import FormInput from '@/components/FormInput.vue'
import Btn from '@/components/Btn.vue'
import Feedback from '@/components/Feedback.vue'
import Upload from '@/components/Upload.vue'

export default defineComponent({
  components: {
    Alert,
    FormInput,
    Btn,
    BackBtn,
    Feedback,
    Upload,
  },
  setup() {
    const route = useRoute()
    const category = computed(() => route?.params?.subcat)
    console.log('😺 SubCat', category.value)

    const showAlert = ref(false)
    const materials = ref(3)
    const roles = ref(3)
    const variant = ref('')

    const uploadPhotos = ref()
    const uploadDocuments = ref()

    const addMaterial = () => {
      materials.value += 1
    }

    const addRole = () => {
      roles.value += 1
    }

    const formel = ref()
    const showError = ref({})
    const badgeFeedback = ref('loading')

    const form = reactive({})

    const feedbackVisible = ref(false)
    const loading = ref(false)

    const showFeedback = () => {
      feedbackVisible.value = true
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onError = (error: any) => {
      feedbackVisible.value = false
      showError.value = error

      showAlert.value = true

      window.setTimeout(() => {
        showAlert.value = false
      }, 5000)
    }

    const onSuccess = () => {
      badgeFeedback.value = 'success'
    }

    const onSubmit = () => {
      if (loading.value) {
        return
      }

      const photos = uploadPhotos.value.getFiles()
      const documents = uploadDocuments.value.getFiles()

      showError.value = {}
      loading.value = true
      feedbackVisible.value = true

      const data = new FormData(formel.value)

      data.append('category', category.value as string)

      data.delete('photos[]')
      if (photos.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        photos.forEach((el: any) => {
          data.append('photos[]', el.file)
        })
      }

      data.delete('documents[]')
      if (documents.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        documents.forEach((el: any) => {
          data.append('documents[]', el.file)
        })
      }

      create(data)
        .then(d => {
          if (d.data?.status === 200) {
            onSuccess()
          } else {
            onError(d.data)
          }
        })
        .catch(() => {
          onError({
            message: !navigator.onLine
              ? 'Il semblerait que tu sois hors connexion. Connecte toi à internet pour envoyer ton formulaire.'
              : "Il semblerait qu'une erreur ce soit glissée quelque part.",
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      form,
      feedbackVisible,
      showFeedback,
      onSubmit,
      loading,
      formel,
      showError,
      badgeFeedback,
      variant,
      materials,
      addMaterial,
      roles,
      addRole,
      showAlert,

      uploadPhotos,
      uploadDocuments,
    }
  },
})
</script>

<style lang="scss" scoped>
.game-form {
  @include form-base;

  background: $c-white;
}

@include mq(l) {
  .infos {
    display: flex;
    justify-content: space-between;

    > * {
      flex-basis: 30%;
      margin-bottom: 0;
    }
  }

  .variants {
    display: flex;

    > * {
      flex-basis: 50%;
    }

    .variant-desc {
      padding-top: 0;
      border-top: 0;
    }
  }
}
</style>
