
import { computed, defineComponent, ref, reactive } from 'vue'
import { create } from '@/guides/endpoints/activity'
import { useRoute } from 'vue-router'

import Alert from '@/components/Alert.vue'
import BackBtn from '@/components/BackBtn.vue'
import FormInput from '@/components/FormInput.vue'
import Btn from '@/components/Btn.vue'
import Feedback from '@/components/Feedback.vue'
import Upload from '@/components/Upload.vue'

export default defineComponent({
  components: {
    Alert,
    FormInput,
    Btn,
    BackBtn,
    Feedback,
    Upload,
  },
  setup() {
    const route = useRoute()
    const category = computed(() => route?.params?.subcat)
    console.log('😺 SubCat', category.value)

    const showAlert = ref(false)
    const materials = ref(3)
    const roles = ref(3)
    const variant = ref('')

    const uploadPhotos = ref()
    const uploadDocuments = ref()

    const addMaterial = () => {
      materials.value += 1
    }

    const addRole = () => {
      roles.value += 1
    }

    const formel = ref()
    const showError = ref({})
    const badgeFeedback = ref('loading')

    const form = reactive({})

    const feedbackVisible = ref(false)
    const loading = ref(false)

    const showFeedback = () => {
      feedbackVisible.value = true
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onError = (error: any) => {
      feedbackVisible.value = false
      showError.value = error

      showAlert.value = true

      window.setTimeout(() => {
        showAlert.value = false
      }, 5000)
    }

    const onSuccess = () => {
      badgeFeedback.value = 'success'
    }

    const onSubmit = () => {
      if (loading.value) {
        return
      }

      const photos = uploadPhotos.value.getFiles()
      const documents = uploadDocuments.value.getFiles()

      showError.value = {}
      loading.value = true
      feedbackVisible.value = true

      const data = new FormData(formel.value)

      data.append('category', category.value as string)

      data.delete('photos[]')
      if (photos.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        photos.forEach((el: any) => {
          data.append('photos[]', el.file)
        })
      }

      data.delete('documents[]')
      if (documents.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        documents.forEach((el: any) => {
          data.append('documents[]', el.file)
        })
      }

      create(data)
        .then(d => {
          if (d.data?.status === 200) {
            onSuccess()
          } else {
            onError(d.data)
          }
        })
        .catch(() => {
          onError({
            message: !navigator.onLine
              ? 'Il semblerait que tu sois hors connexion. Connecte toi à internet pour envoyer ton formulaire.'
              : "Il semblerait qu'une erreur ce soit glissée quelque part.",
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      form,
      feedbackVisible,
      showFeedback,
      onSubmit,
      loading,
      formel,
      showError,
      badgeFeedback,
      variant,
      materials,
      addMaterial,
      roles,
      addRole,
      showAlert,

      uploadPhotos,
      uploadDocuments,
    }
  },
})
